import { Component, Emit, Prop } from 'vue-property-decorator';
import { Button, InputField, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/select/Select';
import { VueComponent } from '~/utils/vue-component';

import style from './SafeTravelsFilter.scss';
import { ThemeColors } from '~/utils/theme';

const rootClass = 'czt-safe-travels-filter';

export type FilterEmit = FilterData;

interface FilterData {
  fulltext: string;
  categories: string[];
}
export interface SafeTravelsListFilterOptions {
  categories: ItemData[];
}

interface SafeTravelsListFilterInterface {
  filterOptions: SafeTravelsListFilterOptions;
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
}

@Component({
  style,
})
export default class SafeTravelsListFilter
  extends VueComponent<SafeTravelsListFilterInterface>
  implements SafeTravelsListFilterInterface {
  @Prop({ required: true })
  public filterOptions!: SafeTravelsListFilterOptions;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  protected get categoryOptions(): ItemData[] {
    return this.filterOptions.categories.map((category) => {
      return {
        text: this.$te(`app.safeTravels.categoryTranslations.${category.value}`)
          ? this.$t(
              `app.safeTravels.categoryTranslations.${category.value}`
            ).toString()
          : category.text,
        value: category.value,
      };
    });
  }
  protected search: string = '';
  protected categories: string[] = [];

  public render() {
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' md='6'>
                        <InputField
                          class={`${rootClass}__input`}
                          v-model={this.search}
                          clearable
                          placeholder={this.$t('app.safeTravels.search')}
                        />
                      </v-col>
                      <v-col cols='12' md='6'>
                        <Multiselect
                          disabled={this.categoryOptions.length < 2}
                          items={this.categoryOptions}
                          v-model={this.categories}
                          placeholder={this.$t('app.safeTravels.categories')}
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button loading={this.loading} submit>
                  {this.$t('app.safeTravels.button')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return {
      categories: this.categories,
      fulltext: this.search,
    };
  }
}
